import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import * as types from './mutation-types';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
    }
});

export default store;