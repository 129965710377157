
    import { BModal } from 'bootstrap-vue/esm';
    import { Component, Vue } from 'vue-property-decorator';
    import { Info } from '@/types/common/info';
    import { ModalForm } from '@/types/common/modal-form';

    @Component
    export default class InfoModal extends Vue implements ModalForm {
        $refs!: {
            modal: BModal
        }

        infoText: string = '';
        title: string = '';
        danger: boolean = false;
        size: string = 'sm';

        async show(args?: any) {
            this.$refs.modal.show();
            this.beforeShow(args);
        }

        beforeShow(model: Info) {
            this.title = model.title;
            this.infoText = model.text;
            this.danger = model.danger;

            if (model.size)
                this.size = model.size;
        }

        cancel() {
            this.$refs.modal.hide();
        }
    }
