import BaseService from "@/services/base-service";
import { SelectListOption } from "@entrypointdev/datatables.net-vue/dist/src/components/filters/mixins/select-mixin";
import { UserPermissionValue } from "../constants/user-permission";
import UserIntercomViewModel from "../types/viewmodels/user/user-intercom-view-model";
import { CancelTokenSource } from "axios";


class UserService extends BaseService {

    getDistrictsCancelTokens: CancelTokenSource[] = [];
    async getDistricts(userId: number, permission: UserPermissionValue, allOption = true, token?: CancelTokenSource) {
        try {
            this.getDistrictsCancelTokens = this.resetCancelTokenSource(this.getDistrictsCancelTokens, token);
            const request = this.getDistrictsCancelTokens[0];
            return (await (this.get<SelectListOption[]>('/user/getDistricts', { cancelToken: request.token, params: { userId, permission, allOption } }))).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    getDistrictsNamesCancelTokens: CancelTokenSource[] = [];
    async getDistrictsNames(userId: number, permission: UserPermissionValue, allOption = true, token?: CancelTokenSource) {
        try {
            this.getDistrictsNamesCancelTokens = this.resetCancelTokenSource(this.getDistrictsNamesCancelTokens, token);
            const request = this.getDistrictsNamesCancelTokens[0];
            return (await (this.get<SelectListOption[]>('/user/getDistrictsNames', { cancelToken: request.token, params: { userId, permission, allOption } }))).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    getSchoolsCancelTokens: CancelTokenSource[] = [];
    async getSchools(userId: number, permission: UserPermissionValue, allOption = true, districtId = null, gradeLevel = null, token?: CancelTokenSource) {
        try {
            this.getSchoolsCancelTokens = this.resetCancelTokenSource(this.getSchoolsCancelTokens, token);
            const request = this.getSchoolsCancelTokens[0];
            return (await (this.get<SelectListOption[]>('/user/getSchools', { cancelToken: request.token, params: { userId, permission, allOption, districtId, gradeLevel } }))).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    getGradesCancelTokens: CancelTokenSource[] = [];
    async getGrades(userId: number, permission: UserPermissionValue, allOption = true, schoolId = null, gradeLevel = null, token?: CancelTokenSource) {
        try {
            this.getGradesCancelTokens = this.resetCancelTokenSource(this.getGradesCancelTokens, token);
            const request = this.getGradesCancelTokens[0];
            return (await (this.get<SelectListOption[]>('/user/getGrades', { cancelToken: request.token, params: { userId, permission, allOption, schoolId, gradeLevel } }))).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    getTeachersCancelTokens: CancelTokenSource[] = [];
    async getTeachers(districtId: number, schoolId: number, permission: UserPermissionValue, gradeId = null, allOption = true, userId = null, token?: CancelTokenSource) {
        try {
            this.getTeachersCancelTokens = this.resetCancelTokenSource(this.getTeachersCancelTokens, token);
            const request = this.getTeachersCancelTokens[0];
            return (await (this.get<SelectListOption[]>('/user/getTeachers', { cancelToken: request.token, params: { districtId, schoolId, permission, gradeId, allOption, userId } }))).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    getDisciplinesCancelTokens: CancelTokenSource[] = [];
    async getDisciplines(allOption = true, allowCustom = true, token?: CancelTokenSource) {
        try {
            this.getDisciplinesCancelTokens = this.resetCancelTokenSource(this.getDisciplinesCancelTokens, token);
            const request = this.getDisciplinesCancelTokens[0];
            return (await (this.get<SelectListOption[]>('/user/getDisciplines', { cancelToken: request.token, params: { allOption, allowCustom } }))).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    getIntercomInfoCancelTokens: CancelTokenSource[] = [];
    async getIntercomInfo(token?: CancelTokenSource): Promise<UserIntercomViewModel> {
        try {
            this.getIntercomInfoCancelTokens = this.resetCancelTokenSource(this.getIntercomInfoCancelTokens, token);
            const request = this.getIntercomInfoCancelTokens[0];
            return (await (this.get<UserIntercomViewModel>('/user/getIntercomInfo', { cancelToken: request.token }))).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    getStatesCancelTokens: CancelTokenSource[] = [];
    async getStates(token?: CancelTokenSource) {
        try {
            this.getStatesCancelTokens = this.resetCancelTokenSource(this.getStatesCancelTokens, token);
            const request = this.getStatesCancelTokens[0];
            return (await (this.get<SelectListOption[]>('/user/getStates', { cancelToken: request.token }))).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }
}

export default new UserService();