
    import { Component, Vue } from 'vue-property-decorator';

    import { routes } from '@/routes/routes';

    @Component
    export default class NavMenu extends Vue {
        showCollapsed = false;

        get isAuthenticated() {
            return this.$auth.isAuthenticated;
        }

        get user() {
            return this.$auth.user;
        }

        get isLoggedInAsAnotherUser() {
            return this.$auth.isLoggedInAsAnotherUser;
        }

        get visibleRoutes() {
            return routes.filter(route => {
                if (!(route.meta && route.meta.showInMenu))
                    return false;

                if (!route.meta.auth)
                    return true;

                return this.$auth.isRouteAccessible(route);
            });
        }

        toggleCollapsed() {
            this.showCollapsed = !this.showCollapsed;
        }

        toggleCollapsedOnClick() {
            if (this.showCollapsed)
                this.showCollapsed = false;
        }

        async loginBackAsAdmin() {
            await this.$auth.loginBackAsAdmin();
        }

        async logout() {
            await this.$auth.logout();
        }
    }
