
    import { Component, Prop, Mixins } from 'vue-property-decorator';
    import notificationService from '../../../services/notification-service';
    import notificationMixin from '../../../mixins/notification-mixin';
    import { NotificationType } from "../../../constants/notification-type";

    @Component
    export default class SidebarNotification extends Mixins(notificationMixin) {
        @Prop({ type: Array as () => any[], required: true, default: [] })
        item: any[];

        isLoading: boolean = false;

        async created() {
            this.isLoading = true;

            this.notifications = await notificationService.getNotifications();

            this.taskNotificationTypes = await notificationService.getUserTaskTypes();

            this.isLoading = false;
        }

        async markAllRead() {
            await notificationService.readAllNotification();
            this.notifications = [];
        }

        taskRedirect(taskType: NotificationType) {
            let sectionIndex = 0;

            if (taskType === NotificationType.RequestDocument)
                sectionIndex = 1;

            this.$router.push({ path: '/Task', query: { sectionIndex: sectionIndex.toString() } }).catch(() => { });
        }
    }
