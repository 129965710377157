
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import NavMenu from '@/components/views/nav-menu.vue';
    import SidebarMenu from '@/components/common/sidebar/SidebarMenu.vue';
    import { routes } from '@/routes/routes';
    import { SidebarMenuModel } from '@/types/common/ref-types';
    import homeService from '../../services/home-service';
    import { Info } from '../../types/common/info';
    import InfoModal from '@/components/common/info-modal.vue';
    import { ModalForm } from '../../types/common/modal-form';
    import IdleModal from '@/components/common/idle-modal.vue';
    import Garland from '../common/christmas/garland.vue';
    import userService from '../../services/user-service';
    import UserIntercomViewModel from '../../types/viewmodels/user/user-intercom-view-model';
    import { GeneralConstant } from '../../constants/constant';


    @Component({
        components: {
            NavMenu,
            SidebarMenu,
            InfoModal,
            IdleModal,
            Garland
        }
    })
    export default class BaseLayout extends Vue {
        $refs!: {
            sideMenu: SidebarMenuModel & Vue,
            infoModalRef: InfoModal & ModalForm,
            idleModalRef: IdleModal & ModalForm
        }

        menu: any[] = [];
        menuFooter: any[] = [];
        showBackDrop: boolean = false;
        menuCustom: any[] = [
            {
                after: 'manage',
                path: 'https://ileadr.com/rti-stored-success-guide/',
                external: false,
                display: '',
                icon: 'help',
                children: []
            }
        ];

        @Watch('isAuthenticated')
        async isAuthenticatedWatcher() {
            this.drawSidebar();

            if (this.isAuthenticated)
                await this.drawIntercom();
            else {
                this.$intercom.shutdown();

                localStorage.removeItem(GeneralConstant.intercomInfoKey);
            }
        }

        @Watch('userInformation')
        async userInformationWatcher() {
            localStorage.removeItem(GeneralConstant.intercomInfoKey);

            await this.drawIntercom();
        }

        @Watch('visibleRoutes')
        visibleRoutesWatcher() {
            this.drawSidebar();
        }

        @Watch('isIdle')
        idleWatcher() {
            if (this.isIdle && this.isAuthenticated)
                this.$refs.idleModalRef.show();
        }

        async openPrivacyPolicy() {
            let privacyText = await homeService.getPrivacyPolicy();

            this.$refs.infoModalRef.show({
                text: privacyText,
                title: "Privacy Policy",
                danger: false,
                size: "xl"
            } as Info);
        }

        async loginBackAsAdmin() {
            await this.$auth.loginBackAsAdmin();
        }

        get isAuthenticated() {
            return this.$auth.isAuthenticated;
        }

        get userInformation() {
            return this.$auth.user;
        }

        get isLoggedInAsAnotherUser() {
            return this.$auth.isLoggedInAsAnotherUser;
        }

        get isIdle() {
            if (!this.$store.state.idleVue)
                return false;

            return this.$store.state.idleVue.isIdle;
        }

        async mounted() {
            this.drawSidebar();
            await this.drawIntercom();
        }

        private closeMenu() {
            if (!this.showBackDrop)
                return;

            this.$refs.sideMenu.closeMenu();
        }

        private drawSidebar() {
            this.menu = [];
            this.menuFooter = [];

            this.visibleRoutes.forEach(route => {
                if (route.path === '/')
                    return;

                let children: any[] = [];

                if (route.meta.showChildren) {
                    route.children.filter(c => this.isChildrenAccessible(c)).forEach(child => {
                        let childrenChildrens: any[] = [];
                        let childData = {
                            id: '',
                            href: `${route.path}/${child.path}`,
                            isSubTitle: false,
                            title: child.display,
                            isNotification: child.meta.isNotification,
                            isTask: child.meta.isTask,
                            child: []
                        };

                        if (child.meta.showChildren)
                            child.children.filter(c => this.isChildrenAccessible(c)).forEach(otherChild => {
                                childrenChildrens.push({
                                    id: '',
                                    href: `${child.path}/${otherChild.path}`,
                                    title: otherChild.display,
                                });
                            });

                        childData.child = childrenChildrens;
                        childData.isSubTitle = childrenChildrens.length ? true : false;
                        children.push(childData);
                    });
                }

                let menuItem = {
                    id: '',
                    href: route.meta.showChildren ? '' : route.path,
                    title: route.display,
                    icon: route.meta.icon,
                    isSubTitle: false,
                    isFirstDisabled: true,
                    child: children,
                    isNotification: route.meta.isNotification,
                    isStudentListComponent: route.meta.isStudentListComponent,
                    isPLCComponent: route.meta.isPLCComponent
                };

                if (route.meta.isFooterMenu)
                    this.menuFooter.push(menuItem);
                else
                    this.menu.push(menuItem);

                this.menuCustom.forEach(customRoute => {
                    if (this.visibleRoutes.length === this.menuFooter.length + this.menu.length) {
                        let menuCustomItem = {
                            id: '',
                            href: customRoute.path,
                            external: customRoute.external,
                            isSubTitle: false,
                            isFirstDisabled: true,
                            title: customRoute.display,
                            icon: customRoute.icon,
                            child: customRoute.children
                        };

                        if (route.meta.isFooterMenu)
                            this.menuFooter.push(menuCustomItem);
                        else
                            this.menu.push(menuCustomItem);
                    }
                });
            });

            if (!this.isAuthenticated) {
                this.menuFooter.push({
                    id: '',
                    href: '/login',
                    title: 'Login',
                    icon: 'login',
                    isSubTitle: false,
                    isFirstDisabled: true,
                    child: []
                });
                this.menuFooter.push({
                    id: '',
                    href: '/register',
                    title: 'Register',
                    icon: 'how_to_reg',
                    isSubTitle: false,
                    isFirstDisabled: true,
                    child: []
                });
            } else {
                this.menuFooter.push({
                    href: '',
                    id: 'account',
                    title: 'Account',
                    icon: 'account_circle',
                    isSubTitle: false,
                    isFirstDisabled: true,
                    child: []
                });
            }
        }

        private get visibleRoutes() {
            return routes.filter(route => {
                if (!(route.meta && route.meta.showInMenu))
                    return false;

                if (!route.meta.auth)
                    return true;
                return this.$auth.isRouteAccessible(route);
            });
        }

        private isChildrenAccessible(route) {
            if (!route.meta)
                return false;

            if (!route.meta.auth)
                return true;
            return this.$auth.isRouteAccessible(route);
        }

        private async drawIntercom() {
            if (this.isAuthenticated && this.$auth.user) {
                let jsonData = localStorage.getItem(GeneralConstant.intercomInfoKey);

                let intercomData = jsonData !== GeneralConstant.emptyJson
                    ? JSON.parse(jsonData) as UserIntercomViewModel : null;

                if (!intercomData) {
                    intercomData = await userService.getIntercomInfo();

                    localStorage.setItem(GeneralConstant.intercomInfoKey, JSON.stringify(intercomData));
                }

                this.$intercom.boot({
                    user_id: this.$auth.user.sub,
                    name: this.$auth.user.name,
                    email: this.$auth.user.email,
                    job_title: intercomData.role,
                    School: intercomData.school,
                    District: intercomData.district
                });

                this.$intercom.hide();
            }
        }
    }
