
    import { Component, Vue } from 'vue-property-decorator';
    import '@/components/common/christmas/garland.scss';


    @Component
    export default class Garland extends Vue {
        parts = [1, 2, 3, 4, 5, 6];
        items = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        subItems = [1, 2, 3, 4, 5, 6];

        mounted() {
            this.setBallsBounce();
            this.setBallsPosition();
        }

        setBallsBounce() {
            let balls = document.querySelectorAll('.b-ball_bounce');
            let ballsRight = document.querySelectorAll('.b-ball_bounce .b-ball__right');

            let self = this;

            for (let i = 0; i < balls.length; i++) {
                balls[i].addEventListener('mouseenter', function () {
                    self.ballBounce(this);
                });
            }

            for (let i = 0; i < ballsRight.length; i++) {
                ballsRight[i].addEventListener('mouseenter', function () {
                    self.ballBounce(this);
                });
            }
        }

        setBallsPosition() {
            let ballsPositions = ["49", "50", "51", "52", "53", "54", "55", "56", "57", "48", "189", "187", "81", "87", "69", "82", "84", "89", "85", "73", "79", "80", "219", "221", "65", "83", "68", "70", "71", "72", "74", "75", "76", "186", "222", "220"];
            let ballsRightPositions = ["90", "88", "67", "86", "66", "78", "77", "188", "190", "191"];
            let garland = {};

            for (let e = 0, c = ballsPositions.length; e < c; e++)
                garland[ballsPositions[e]] = e;

            for (let e = 0, c = ballsRightPositions.length; e < c; e++)
                garland[ballsRightPositions[e]] = e;

            let self = this;

            document.addEventListener('keydown', function (j) {
                if (j.which in garland) {
                    let index = parseInt(garland[j.which]);
                    let ball = document.querySelector('[data-note="' + index + '"]');

                    self.toggleBounce(ball);
                }
            });
        }

        ballBounce(e) {
            let i = e;

            if (e.className.indexOf(" bounce") > -1)
                return;

            this.toggleBounce(i);
        }

        toggleBounce(i) {
            i.classList.add("bounce");

            function n() {
                i.classList.remove("bounce");
                i.classList.add("bounce1");

                function o() {
                    i.classList.remove("bounce1");
                    i.classList.add("bounce2");

                    function p() {
                        i.classList.remove("bounce2");
                        i.classList.add("bounce3");

                        function q() {
                            i.classList.remove("bounce3");
                        }

                        setTimeout(q, 300);
                    }
                    setTimeout(p, 300);
                }
                setTimeout(o, 300);
            }
            setTimeout(n, 300);
        }
    }
