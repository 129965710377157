export enum GradeLevel {
    None = 0,
    Elementary = 1,
    Middle = 2,
    High = 4,
    College = 8
}

export const GradeLevelName = new Map<number, string>([
    [GradeLevel.None, 'None'],
    [GradeLevel.Elementary, 'Elementary'],
    [GradeLevel.Middle, 'Middle'],
    [GradeLevel.High, 'High'],
    [GradeLevel.College, 'Early College'],
]);

