import BaseService from "@/services/base-service";
import NotificationViewModel from "@/types/viewmodels/notification-view-model";
import MeetingListViewModel from "@/types/viewmodels/tasks/meeting-list-view-model";
import TaskMeetingViewModel from "@/types/viewmodels/tasks/task-meeting-view-model";
import { NotificationType } from '../constants/notification-type';

class NotificationService extends BaseService {
    async getNotifications() {
        try {
            return (await this.get<NotificationViewModel[]>('/notification/getNotifications')).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async getUserTaskTypes() {
        try {
            return (await this.get<NotificationType[]>('/notification/getUserTaskTypes')).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async getUserNotificationCount() {
        try {
            return (await this.get<number>('/notification/getUserNotificationCount')).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async getAllNotifications() {
        try {
            return (await this.get<NotificationViewModel[]>('/notification/getAllNotifications')).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async getTaskListMeetings() {
        try {
            return (await this.get<MeetingListViewModel>('/notification/getTaskListMeetings')).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async readAllNotification() {
        try {
            await this.post('/notification/readAllNotification');
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async readNotification(model: NotificationViewModel) {
        try {
            await this.post('/notification/readNotification', model);
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async acceptMeeting(model: TaskMeetingViewModel) {
        try {
            await this.post('/notification/acceptMeeting', model);
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async rejectMeeting(model: TaskMeetingViewModel) {
        try {
            await this.post('/notification/rejectMeeting', model);
        }
        catch (error) {
            this.ThrowError(error);
        }
    }
}

export default new NotificationService();