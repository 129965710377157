const defaults = {
    tokenUri: '/connect/token',
    logoutUri: '/connect/logout',
    userInfoUri: '/connect/userinfo',
    logoutRedirectTo: 'default',
    unauthorizedRedirectTo: 'login',
    forbiddenRedirectTo: 'forbidden',
    scope: 'openid offline_access email profile roles',
    rememberMeDuration: 14, //in days
    tokenStorageType: 'localStorage',
    tokenStorageNamespace: 'oidc-client',
    loginProviders: {
        configuredProvidersUri: '/connect/loginproviders',
        redirectUri: '/connect/token',
        addLoginUri: 'api/profile/addproviderlogin'
    }
};

export default defaults;