
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import { InputFilterMixin } from '@entrypointdev/datatables.net-vue';
    import { defaults } from '../defaults';

    @Component
    export default class FilterInput extends Mixins(InputFilterMixin) {
        @Prop({ type: String, default: defaults.options.language.searchPlaceholder })
        placeholder!: string;

        @Prop({ type: String })
        size?: string;
    }
