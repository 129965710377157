
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { GradeLevel } from '../../../constants/gradeLevel';


    @Component
    export default class SidebarStudentListItem extends Vue {
        @Prop({ type: Array as () => any[], required: true, default: () => [] })
        item: any[];


        showRouteBySchoolAccess(href: string) {
            if (href.includes(GradeLevel.Elementary.toString()))
                return this.$auth.userInfo.has_elementary_school;

            else if (href.includes(GradeLevel.Middle.toString()))
                return this.$auth.userInfo.has_middle_school;

            return this.$auth.userInfo.has_high_school;
        }
    }
