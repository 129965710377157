
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { DEFAULT_RANGES } from '@/constants/dates';
    import { DateRangeSelectValue, PickerField } from '@/types/viewmodels/form-fields';

    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
    import './date-range-select.scss';

    @Component({
        components: {
            'date-range-picker': DateRangePicker
        }
    })
    export default class DateRangeSelect extends Vue {
        $refs!: {
            picker: Vue & PickerField
        }

        @Prop({ type: [Object, Boolean], default: DEFAULT_RANGES })
        ranges!: object | boolean;

        @Prop({ type: Object as () => DateRangeSelectValue, default: null })
        value!: DateRangeSelectValue | null;

        @Prop({ type: Boolean, default: false })
        autoApply!: boolean;

        @Prop({ type: String, default: 'right' })
        opens!: string;

        @Prop({ type: String, default: 'Date range' })
        placeholder!: string;

        @Prop({ type: String, default: 'form-control' })
        inputClass!: string;

        dateRange: DateRangeSelectValue = { startDate: null, endDate: null };
        selectedValue: null;

        @Watch('value')
        valueWatcher(newValue: DateRangeSelectValue | null) {
            this.dateRange = newValue ?? { startDate: null, endDate: null };
        }

        @Watch('dateRange')
        dateRangeWatcher(newRange: DateRangeSelectValue) {
            this.$emit('input', newRange);
        }

        created() {
            if (this.value && Object.keys(this.value).length)
                this.dateRange = this.value;
        }

        async clearSelection() {
            this.dateRange = { startDate: null, endDate: null };

            this.$refs.picker.clickCancel();
        }
    }
