export enum UserPermission {
	Dashboard = "Dashboard",
	VisionScreening = "VisionScreening",
	HearingScreening = "HearingScreening",
	SpeechScreening = "SpeechScreening",
	ProgressMonitoringTables = "ProgressMonitoringTables",
	NewProgressMonitoringTables = "NewProgressMonitoringTables",
	EnvironmentalInventory = "EnvironmentalInventory",
	InterventionGroups = "InterventionGroups",
	UserManagement = "UserManagement",
	RoleManagement = "RoleManagement",
	DistrictAdminAccessManagement = "DistrictAdminAccessManagement",
	SchoolAdminAccessManagement = "SchoolAdminAccessManagement",
	TeacherAccessManagement = "TeacherAccessManagement",
	NurseAccessManagement = "NurseAccessManagement",
	SpeechPathologistAccessManagement = "SpeechPathologistAccessManagement",
	AssistantPrincipalAccessManagement = "AssistantPrincipalAccessManagement",
	DistrictModeratorAccessManagement = "DistrictModeratorAccessManagement",
	SchoolCounselorAccessManagement = "SchoolCounselorAccessManagement",
	InterventionistAccessManagement = "InterventionistAccessManagement",
	StateAdminAccessManagement = "StateAdminAccessManagement",
	SiteManagement = "SiteManagement",
	ChecklistManagement = "ChecklistManagement",
	ChecklistTablesManagement = "ChecklistTablesManagement",
	ChecklistDocumentTitleManagement = "ChecklistDocumentTitleManagement",
	PlcManagement = "PlcManagement",
	DistrictTier1ExpectationsManagement = "DistrictTier1ExpectationsManagement",
	BehaviorTier1ExpectationsManagement = "BehaviorTier1ExpectationsManagement",
	BehaviorPLCPlanManagement = "BehaviorPLCPlanManagement",
	InterventionsManagement = "InterventionsManagement",
	InterventionTablesManagement = "InterventionTablesManagement",
	InterventionToolsManagement = "InterventionToolsManagement",
	CoreBestPracticesManagement = "CoreBestPracticesManagement",
	PracticesTablesManagement = "PracticesTablesManagement",
	PracticesToolsManagement = "PracticesToolsManagement",
	DistrictsManagement = "DistrictsManagement",
	SchoolsManagement = "SchoolsManagement",
	FAQManagement = "FAQManagement",
	SiteAccess = "SiteAccess",
	ReportsAccess = "ReportsAccess",
	GeneralReportsAccess = "GeneralReportsAccess",
	IReadyMathReportsAccess = "IReadyMathReportsAccess",
	IReadyReadingReportsAccess = "IReadyReadingReportsAccess",
	MClassReportsAccess = "MClassReportsAccess",
	FastBridgeMathReportsAccess = "FastBridgeMathReportsAccess",
	FastBridgeReadingReportsAccess = "FastBridgeReadingReportsAccess",
	EWSAccess = "EWSAccess",
	InterventionGroupsAccess = "InterventionGroupsAccess",
	AddInterventionGroup = "AddInterventionGroup",
	RemoveInterventionGroup = "RemoveInterventionGroup",
	StudentsAccess = "StudentsAccess",
	StudentsAccessAsAdministrator = "StudentsAccessAsAdministrator",
	StudentsAccessAsTeacher = "StudentsAccessAsTeacher",
	StudentsAccessAsMedic = "StudentsAccessAsMedic",
	TasksAccess = "TasksAccess",
	Import = "Import",
	ImportManagement = "ImportManagement",
	ReportImport = "ReportImport",
	StudentsManagement = "StudentsManagement",
	AccessLevel = "AccessLevel",
	GradeLevelAccess = "GradeLevelAccess",
	SchoolLevelAccess = "SchoolLevelAccess",
	DistrictLevelAccess = "DistrictLevelAccess",
	StateLevelAccess = "StateLevelAccess",
	GlobalLevelAccess = "GlobalLevelAccess",
	TaskMeetingAccess = "TaskMeetingAccess",
	TaskRequestedDocumentsAccess = "TaskRequestedDocumentsAccess",
	TaskProgressMonitoringAccess = "TaskProgressMonitoringAccess",
	TaskStudentPlansAccess = "TaskStudentPlansAccess",
	TaskMissingDocumentsAccess = "TaskMissingDocumentsAccess",
	TaskHearingScreeningAccess = "TaskHearingScreeningAccess",
	TaskSpeechScreeningAccess = "TaskSpeechScreeningAccess",
	TaskVisionScreeningAccess = "TaskVisionScreeningAccess",
	ImportDataManagement = "ImportDataManagement",
	PlcAccess = "PlcAccess",
	NewProgressMonitoringLiteracy = "NewProgressMonitoringLiteracy",
	NewProgressMonitoringMath = "NewProgressMonitoringMath",
	NewProgressMonitoringSpeechOnly = "NewProgressMonitoringSpeechOnly",
	EwsSchoolSummaryAccess = "EwsSchoolSummaryAccess",
	EwsDistrictSettingsAccess = "EwsDistrictSettingsAccess",
	EwsRiskReportAccess = "EwsRiskReportAccess",
	Plan504Access = "Plan504Access",
	Plan504AddOrEditAccess = "Plan504AddOrEditAccess",
	Plan504ViewOnlyAccess = "Plan504ViewOnlyAccess",
	ProgressMonitoringTablesLiteracy = "ProgressMonitoringTablesLiteracy",
	ProgressMonitoringTablesMath = "ProgressMonitoringTablesMath",
	ProgressMonitoringTablesSpeechOnly = "ProgressMonitoringTablesSpeechOnly",
	MiscellaneousAccess = "MiscellaneousAccess",
	RequestedForDocumentAccess = "RequestedForDocumentAccess",
	InvitedToMeetingAccess = "InvitedToMeetingAccess",
	InvitedToReferralMeetingAccess = "InvitedToReferralMeetingAccess",
	DashBoardReportAccess = "DashBoardReportAccess",
	DessaReportAccess = "DessaReportAccess",
	NewProgressMonitoringHighSchool = "NewProgressMonitoringHighSchool",
	IStationReportAccess = "IStationReportAccess",
	DistrictPMViewAccess = "DistrictPMViewAccess",
	ReportImportReportTab = "ReportImportReportTab",
	ReportImportUploadedFilesTab = "ReportImportUploadedFilesTab",
	InitialSetup = "InitialSetup",
	DistrictSetup = "DistrictSetup",
	SchoolSetup = "SchoolSetup",
	NewProgressMonitoringSocialEmotionalLearning = "NewProgressMonitoringSocialEmotionalLearning",
	ProgressMonitoringSocialEmotionalLearning = "ProgressMonitoringSocialEmotionalLearning",
	SocialEmotionalLearningPLCPlanManagement = "SocialEmotionalLearningPLCPlanManagement",
	SocialEmotionalTier1ExpectationsManagement = "SocialEmotionalTier1ExpectationsManagement",
	MapReportAccess = "MapReportAccess",
	SpeechIEPAccess = "SpeechIEPAccess",
	PanoramaReportAccess = "PanoramaReportAccess",
	RethinkReportAccess = "RethinkReportAccess",
	AcadienceReportAccess = "AcadienceReportAccess",
	WrittenExpressionAccess = "WrittenExpressionAccess",
	EOGReportAccess = "EOGReportAccess",
	KRAReportAccess = "KRAReportAccess",
	SCAssessmentsReportAccess = "SCAssessmentsReportAccess",
	CheckInReportAccess = "CheckInReportAccess",
	StudentProfileAccess = "StudentProfileAccess",
	PrivateDocumentAccess = "PrivateDocumentAccess",
	ByPassOptionAccess = "ByPassOptionAccess",
	StarReportAccess = "StarReportAccess",
	ImportedReportsAccess = "ImportedReportsAccess",
	MicrophaseReportAccess = "MicrophaseReportAccess",
	ELACommonTasksReportAccess = "ELACommonTasksReportAccess",
	MathCommonTasksReportAccess = "MathCommonTasksReportAccess",
}

export enum UserPermissionValue {
    Dashboard = 1,
    VisionScreening = 2,
    HearingScreening = 3,
    SpeechScreening = 4,
    ProgressMonitoringTables = 5,
    NewProgressMonitoringTables = 6,
    EnvironmentalInventory = 7,
    InterventionGroups = 8,
    UserManagement = 9,
    RoleManagement = 10,
    DistrictAdminAccessManagement = 11,
    SchoolAdminAccessManagement = 12,
    TeacherAccessManagement = 13,
    NurseAccessManagement = 14,
    SpeechPathologistAccessManagement = 15,
    AssistantPrincipalAccessManagement = 16,
    DistrictModeratorAccessManagement = 17,
    SchoolCounselorAccessManagement = 18,
    InterventionistAccessManagement = 19,
    StateAdminAccessManagement = 20,
    SiteManagement = 21,
    ChecklistManagement = 22,
    ChecklistTablesManagement = 23,
    ChecklistDocumentTitleManagement = 24,
    PlcManagement = 25,
    DistrictTier1ExpectationsManagement = 26,
    BehaviorTier1ExpectationsManagement = 27,
    BehaviorPLCPlanManagement = 28,
    InterventionsManagement = 29,
    InterventionTablesManagement = 30,
    InterventionToolsManagement = 31,
    CoreBestPracticesManagement = 32,
    PracticesTablesManagement = 33,
    PracticesToolsManagement = 34,
    DistrictsManagement = 35,
    SchoolsManagement = 36,
    FAQManagement = 37,
    SiteAccess = 38,
    ReportsAccess = 39,
    GeneralReportsAccess = 40,
    IReadyMathReportsAccess = 41,
    IReadyReadingReportsAccess = 42,
    MClassReportsAccess = 43,
    FastBridgeMathReportsAccess = 44,
    FastBridgeReadingReportsAccess = 45,
    EWSAccess = 46,
    InterventionGroupsAccess = 47,
    AddInterventionGroup = 48,
    RemoveInterventionGroup = 49,
    StudentsAccess = 50,
    StudentsAccessAsAdministrator = 52,
    StudentsAccessAsTeacher = 53,
    StudentsAccessAsMedic = 54,
    TasksAccess = 57,
    Import = 58,
    ImportManagement = 59,
    ReportImport = 60,
    StudentsManagement = 61,
    AccessLevel = 62,
    GradeLevelAccess = 63,
    SchoolLevelAccess = 64,
    DistrictLevelAccess = 65,
    StateLevelAccess = 66,
    GlobalLevelAccess = 67,
    TaskMeetingAccess = 68,
    TaskRequestedDocumentsAccess = 69,
    TaskProgressMonitoringAccess = 70,
    TaskStudentPlansAccess = 71,
    TaskMissingDocumentsAccess = 72,
    TaskHearingScreeningAccess = 73,
    TaskSpeechScreeningAccess = 74,
    TaskVisionScreeningAccess = 75,
    ImportDataManagement = 76,
    PlcAccess = 77,
    NewProgressMonitoringLiteracy = 78,
    NewProgressMonitoringMath = 79,
    NewProgressMonitoringSpeechOnly = 80,
    EwsSchoolSummaryAccess = 81,
    EwsDistrictSettingsAccess = 82,
    EwsRiskReportAccess = 83,
    Plan504Access = 84,
    Plan504AddOrEditAccess = 85,
    Plan504ViewOnlyAccess = 86,
    ProgressMonitoringTablesLiteracy = 87,
    ProgressMonitoringTablesMath = 88,
    ProgressMonitoringTablesSpeechOnly = 89,
    MiscellaneousAccess = 90,
    RequestedForDocumentAccess = 91,
    InvitedToMeetingAccess = 92,
    InvitedToReferralMeetingAccess = 93,
    DashBoardReportAccess = 94,
    DessaReportAccess = 95,
    NewProgressMonitoringHighSchool = 96,
    IStationReportAccess = 97,
    DistrictPMViewAccess = 98,
    ReportImportReportTab = 99,
    ReportImportUploadedFilesTab = 100,
    InitialSetup = 101,
    DistrictSetup = 102,
    SchoolSetup = 103,
    NewProgressMonitoringSocialEmotionalLearning = 104,
    ProgressMonitoringSocialEmotionalLearning = 105,
    SocialEmotionalLearningPLCPlanManagement = 106,
    SocialEmotionalTier1ExpectationsManagement = 107,
    MapReportAccess = 108,
    SpeechIEPAccess = 109,
    PanoramaReportAccess = 110,
    RethinkReportAccess = 111,
    AcadienceReportAccess = 112,
    WrittenExpressionAccess = 113,
    EOGReportAccess = 114,
    KRAReportAccess = 115,
    SCAssessmentsReportAccess = 116,
    CheckInReportAccess = 117,
    StudentProfileAccess = 118,
    PrivateDocumentAccess = 119,
    ByPassOptionAccess = 120,
    StarReportAccess = 121,
    ImportedReportsAccess = 122
}