
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { UserPermission } from '../../../constants/user-permission';

    @Component
    export default class SidebarStudentListItem extends Vue {
        @Prop({ type: Array as () => any[], required: true, default: [] })
        item: any[];

        goToCurrentAccessPage(href: string) {
            let splitted = href.split('/');

            if (this.$auth.hasPermission(UserPermission.StudentsAccessAsAdministrator) && !window.location.href.includes('Teacher'))
                return href;

            return `/${(this.$auth.hasPermission(UserPermission.StudentsAccessAsTeacher) ? 'Teacher' : 'Medic')}/${splitted[2]}`;
        }

        showRouteBySchoolAccess(href: string) {
            if (href.includes('HighSchool'))
                return this.$auth.userInfo.has_high_school;
            return this.$auth.userInfo.has_elementary_school || this.$auth.userInfo.has_middle_school;
        }
    }
