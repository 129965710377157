import Vue from 'vue';

import hello from 'hellojs';
import Cookies from 'js-cookie';
import qs from 'qs';

import ApiError from '@/utils/api-error';

import StorageFactory from './storage/storage-factory'
import { isEmptyObject, objectExtend } from './utils';
import defaults from './defaults';
import { AuthOptions, UserInfo, TokenData, LoginProviderSettings, LoginData, AccessTokenData } from './auth-types';
import { AxiosStatic, AxiosRequestConfig, AxiosResponse } from 'axios';
import VueRouter, { Route, RawLocation } from 'vue-router';
import CookieStorage from '@/auth/storage/cookie-storage';
import LocalStorage from '@/auth/storage/local-storage';
import * as crypto from "crypto-js";

/**
* OpenID Connect/OAuth 2.0 auth manager.
*
* Handles user authentication and token-based authorization by using OpenID Connect/OAuth 2.0 standards.
*/
export default class AuthManager {

	constructor($http: AxiosStatic, options: AuthOptions) {

		this.$http = $http;
		this.options = objectExtend(defaults, options);
		this.storage = StorageFactory(options);
		this.router = options.router;

		//define internal reactive state
		this.internalState = new Vue({
			data: function () {
				return {
					tokenData: null,
					userInfo: null,
					loginProviders: null
				};
			}
		});

		//define getters and setters
		Object.defineProperties(this, {
			tokenData: {
				get() {
					if (!this.storage.getItem('tokenData')) return null;

					return JSON.parse(this.storage.getItem('tokenData'));
				},
				set(object) {
					this.internalState.tokenData = object;
					this.storage.setItem('tokenData', JSON.stringify(object));
				}
			},

			userInfo: {
				get() {
					if (!this.storage.getItem('userInfo')) return null;

					const bytes = crypto.AES.decrypt(this.storage.getItem('userInfo'), process.env.NODE_ENV);
					return JSON.parse(bytes.toString(crypto.enc.Utf8));
				},
				set(object) {
					this.internalState.userInfo = object;
					this.storage.setItem('userInfo', crypto.AES.encrypt(JSON.stringify(object), process.env.NODE_ENV));
				}
			}
		});


		//add Axios interceptors
		this.$http.interceptors.request.use(config => {

			if (this._tokenExists())
				this._setAuthHeader(config);

			return config;
		});

		this.$http.interceptors.response.use(response => {
			//For new version, simply reload on any get
			let version = response.headers['rti-version'] || 'default';

			if (version !== this.storage.getItem('rtiversion')) {
				localStorage.setItem('rtiversion', version);
				window.location.reload();
			}

			return Promise.resolve(response);
		},
			error => {
				if (this._isInvalidToken(error.response))
					return this._refreshToken(error.config);

				if (error.response) {
					if (error.response.status === 401)
						this.router.push({ name: this.options.unauthorizedRedirectTo, query: { redirect: this.router.currentRoute.path } });

					if (error.response.status === 403)
						this.router.replace({ name: this.options.forbiddenRedirectTo });
				}

				return Promise.reject(error);
			});
	}


	userInfo: UserInfo;
	tokenData: TokenData;
	internalState: { tokenData: TokenData | null; userInfo: UserInfo | null; loginProviders: LoginProviderSettings[]; } & object & Record<never, any> & Vue;
	storage: CookieStorage | LocalStorage;
	router: VueRouter;
	options: AuthOptions;
	$http: AxiosStatic;
	IsRequireReset: any;

	/**
	 * Reactive getter - checks if user is authenticated.
	 *
	* @return {boolean}
	*/
	get isAuthenticated() {
		return this.internalState.tokenData != null;
	}

	/**
	 * Reactive getter - User info received from userinfo endpoint.
	 *
	* @return {Object} user info
	*/
	get user() {
		if (!this.isAuthenticated)
			return null;

		return this.internalState.userInfo;
	}

	/**
	 * Reactive getter - checks if admin is logged in as another user.
	 *
	* @return {boolean}
	*/
	get hasLocalAccount() {
		if (!this.isAuthenticated)
			return false;

		if (!this.user)
			return false;

		return this.user.has_local_account;
	}

	/**
	 * Reactive getter - checks if admin is logged in as another user.
	 *
	* @return {boolean}
	*/
	get isLoggedInAsAnotherUser() {
		if (!this.isAuthenticated)
			return null;

		return this.user && this.user.admin_username;
	}

	/**
	 * Reactive getter - Login providers.
	 *
	* @return {Array} login providers
	*/
	get loginProviders() {
		return this.internalState.loginProviders;
	}

	/**
	 * Initialization
	 *
	 * Check if user session exists and initializes the authentication state.
	 *
	 * @return {void}
	*/
	init(forceRedirectTo: (router: Route) => string) {
		if (this._ensureDataIntegrity()) {
			//restore state from storage if exists
			this.internalState.tokenData = this.tokenData;
			this.internalState.userInfo = this.userInfo;
		}
		else
			this._endSession();

		this._initLoginProviders();
		this._initNavigationGuards(forceRedirectTo);
	}

	/**
	 * Login
	 *
	 * @param {string} username Username for logging in.
	 * @param {string} password Password for logging in.
	 * @param {boolean} rememberMe Remember user or not.
	 * @param {string|null} redirect The route to redirect to.
	 * @return {void}
	 */
	async login(username, password, rememberMe, redirect) {
		const data = {
			grant_type: 'password',
			username: username,
			password: password,
			scope: this.options.scope
		}

		await this._login(data, rememberMe, redirect);
	}

	/**
	* Admin login as user
	*
	* @param {string} username Username to login as.
	* @return {void}
	*/
	async loginAsUser(username) {
		const data = {
			grant_type: 'login_as_user',
			username: username,
			return_to: this.router.currentRoute.path,
			scope: this.options.scope
		}

		await this._login(data, false, { name: 'default' });
	}

	/**
	* Login back as admin
	*
	* @return {void}
	*/
	async loginBackAsAdmin() {
		const data = {
			grant_type: 'login_back_as_admin',
			scope: this.options.scope
		}

		await this._login(data, false, this.user.return_to);
	}

	/**
   * Login via provider
   *
   * @param {string} provider Login provider.
   * @param {string|null} redirect The route to redirect to.
   * @return {Promise}
   */
	async loginViaProvider(provider, redirect) {
		const data = await this.callExternalProvider(provider);

		try {
			let response = await this.$http.post(this.options.tokenUri, qs.stringify(data), { baseURL: '' });

			this._storeToken(response);

			Cookies.set('userSessionMarker', 'userSessionMarker')

			await this.updateUserInfo();

			if (redirect)
				this.router.push(redirect);

			return response;
		}
		catch (error) {
			throw new ApiError(error);
		}
	}

	/**
	 * Login via Clever
	 *
	 * @param {string} code The code that we got from Clever
	 * @param {string|null} redirect The route to redirect to.
	 * @return {Promise}
	 */
	async loginViaClever(code, redirect) {
		try {
			let grantType = this.loginProviders.find((provider) => { return provider.key === 'clever'; }).grantType;

			const data = {
				grant_type: grantType,
				assertion: code,
				name: '',
				scope: this.options.scope
			};

			let response = await this.$http.post(this.options.tokenUri, qs.stringify(data), { baseURL: '' });

			this._storeToken(response);

			Cookies.set('userSessionMarker', 'userSessionMarker')

			await this.updateUserInfo();

			if (redirect)
				this.router.push(redirect);

			return response;
		}
		catch (error) {
			throw new ApiError(error);
		}
	}

	/**
	* Add provider login
	*
	* @param {string} provider Login provider.
	* @return {Promise}
	*/
	async addProviderLogin(provider) {
		const data = await this.callExternalProvider(provider);

		try {
			return await this.$http.post(this.options.loginProviders.addLoginUri, qs.stringify(data), { baseURL: '' });
		}
		catch (error) {
			throw new ApiError(error);
		}
	}

	async callExternalProvider(provider) {
		let providerResponse = await hello.login(provider, { scope: 'email' });
		let userProfile = await hello(provider).api('me');

		let grantType = this.loginProviders.find((provider) => { return provider.key === providerResponse.network; }).grantType;

		const data = {
			grant_type: grantType,
			assertion: providerResponse.authResponse.access_token,
			name: userProfile.name,
			scope: this.options.scope
		}

		return data;
	}

	/**
	 * Updates user info
	 *
	 * @return {void}
	 */
	async updateUserInfo() {
		//try {
		//    let response = await this.$http.get(this.options.userInfoUri, { baseURL: '' });
		//    this._storeUserInfo(response);
		//}
		//catch (error) {
		//    throw new ApiError(error);
		//}

		try {

			let response = await this.$http.get(this.options.userInfoUri, { baseURL: '' });

			this._storeUserInfo(response);
			if (response.data.isrequirereset === "True")
				this.IsRequireReset = true;

		}
		catch (error) {
			throw new ApiError(error);
		}

	}

	/**
	 * Logout
	 *
	 * Clear all data in storage (which resets logged-in status) and redirect.
	 *
	 * @return {void}
	 */
	async logout(redirectUrl) {

		if (this.tokenData)
			await this.$http.get(this.options.logoutUri, { baseURL: '' });

		if (this.loginProviders) {
			for (let provider of this.loginProviders) {
				if (this.storage.getItem(provider.key)) {
					//async call - no need to wait for completion
					hello.logout(provider.key);
				}
			}
		}

		this._endSession(redirectUrl);
	}

	/**
	 * Check if user belongs to specific role.
	 *
	* @param {string} roles A role or array of roles.
	* @return {boolean}
	*/
	hasPermission(permissions) {
		if (!this.user)
			return false;

		let currentPermissions = this.user.permissions.split(',');

		if (typeof (permissions) === 'string')
			return currentPermissions.includes(permissions);

		let isExistAccess = false;

		for (let i = 0; i < permissions.length && !isExistAccess; i++)
			isExistAccess = currentPermissions.includes(permissions[i]);

		return isExistAccess;
	}

	isRouteAccessible(route) {
		if (route.meta && route.meta.auth) {
			if (!this.isAuthenticated)
				return false;

			if (!this._tokenExists())
				return false;

			if (route.meta.auth.permissions)
				return this.hasPermission(route.meta.auth.permissions);
		}

		return true;
	}

	/**
	 * Internal login
	 *
	 * @param {object} data Data to send to the token endpoint.
	 * @param {boolean} rememberMe Remember user or not.
	 * @param {string|null} redirect The route to redirect to.
	 * @return {void}
	 */
	async _login(data, rememberMe, redirect) {
		try {
			let response = await this.$http.post(this.options.tokenUri, qs.stringify(data), { baseURL: '' });

			this._storeToken(response);

			if (rememberMe)
				Cookies.set('userSessionMarker', 'userSessionMarker', { expires: this.options.rememberMeDuration });
			else
				Cookies.set('userSessionMarker', 'userSessionMarker');

			await this.updateUserInfo();

			if (this.IsRequireReset) {
				this.router.push({
					path: '/profile/change-password',
					query: {
						redirect: redirect,
					}
				});
			} else if (redirect) {
				this.router.push(redirect);
			}
		}
		catch (error) {
			throw new ApiError(error);
		}
	}

	/**
	* Initialize Login Providers
	*
	* @return {void}
	*/
	async _initLoginProviders() {
		try {
			let providers = null;
			let response = await this.$http.get(this.options.loginProviders.configuredProvidersUri, { baseURL: '' });

			if (response.data && Array.isArray(response.data)) {
				providers = response.data;

				let helloProviders = {};

				for (let provider of providers)
					helloProviders[provider.key] = provider.id;

				hello.init(helloProviders, { redirect_uri: this.options.loginProviders.redirectUri });
			}

			this.internalState.loginProviders = providers;
		}
		catch (error) {
			throw new ApiError(error);
		}
	}

	/**
	* Clears user's authentication data and redirect's to configured logoutRedirectTo route.
	*
	* @private
	* @return {void}
	*/
	_endSession(redirectUrl = this.options.logoutRedirectTo) {
		Cookies.remove('userSessionMarker');

		this.tokenData = null;
		this.userInfo = null;

		if (this.router.currentRoute.name !== redirectUrl)
			this.router.push({ name: redirectUrl });
	}

	/**
	* Set the Authorization header on Axios Request.
	*
	* @private
	* @param {Object} config The Axios request's config to set the header on.
	* @return {void}
	*/
	_setAuthHeader(config) {
		config.headers['Authorization'] = 'Bearer ' + this.tokenData.accessToken;
	}

	/**
	 * Retry the original request.
	 *
	 * Let's retry the user's original target request that had recieved an invalid token response (which we fixed with a token refresh).
	 *
	 * @private
	 * @param {Object} config The Axios request's config to use to repeat an http call.
	 * @return {Promise}
	 */
	async _retryAfterTokenRefresh(config) {
		//re-write the original request's access token with a refreshed one
		this._setAuthHeader(config);

		return await this.$http.request(config);
	}

	/**
	 * Refresh the access token
	 *
	 * Make an ajax call to the OpenID Connect server to refresh the access token (using our refresh token).
	 *
	 * @private
	 * @param {Object} config The Axios original request's config that we'll retry.
	 * @return {Promise}
	 */
	async _refreshToken(config: AxiosRequestConfig) {
		const data = {
			grant_type: 'refresh_token',
			'refresh_token': this.tokenData.refreshToken
		}

		try {
			let response = await this.$http.post(this.options.tokenUri, qs.stringify(data), { baseURL: '' });

			this._storeToken(response);

			await this.updateUserInfo();

			return this._retryAfterTokenRefresh(config);
		}
		catch (error) {
			this._endSession();
			throw error;
		}
	}

	/**
	 * Store tokens
	 *
	 * Update the storage with the access/refresh tokens received from the token endpoint from the OpenID Connect server.
	 *
	 * @private
	 * @param {Object} response Axios's response instance from the server that contains our tokens.
	 * @return {void}
	 */
	_storeToken(response: AxiosResponse) {
		const newTokenData = this.tokenData || {} as TokenData

		newTokenData.accessToken = response.data.access_token

		if (response.data.refresh_token)
			newTokenData.refreshToken = response.data.refresh_token

		this.tokenData = newTokenData;
	}

	/**
	 * Store user info
	 *
	 * Update the storage with the user info received from the userinfo endpoint from the OpenID Connect server.
	 *
	 * @private
	 * @param {Object} response Axios's response instance from the server that contains userinfo.
	 * @return {void}
	 */
	_storeUserInfo(response) {
		this.userInfo = response.data && !isEmptyObject(response.data) ? response.data : null;
	}

	/**
	 * Check if the Axios's response is an invalid token response.
	 *
	 * @private
	 * @param {Object} response The Axios's response instance received from an http call.
	 * @return {boolean}
	 */
	_isInvalidToken(response) {
		if (!response)
			return false;

		if (!response.headers)
			return false;

		const status = response.status
		const wwwAuthenticateHeader = response.headers['www-authenticate']

		return (status === 401 && wwwAuthenticateHeader && (wwwAuthenticateHeader.includes('invalid_token') || wwwAuthenticateHeader.includes('expired_token')));
	}

	/**
	 * Ensures data integrity.
	 *
	 * @private
	 * @return {boolean}
	 */
	_ensureDataIntegrity() {
		//if there is no token data then session marker should not exist
		if (!this.tokenData && Cookies.get('userSessionMarker'))
			return false;

		//if there is token data then session marker should exist as well
		if (this.tokenData && !Cookies.get('userSessionMarker'))
			return false;

		return true;
	}

	/**
	 * Checks if token data exists.
	 *
	 * @private
	 * @return {boolean}
	 */
	_tokenExists() {
		return this._ensureDataIntegrity() && this.tokenData;
	}

	/**
	* Client-side authorization checks for navigation.
	*
	* @private
	* @return {boolean}
	*/
	_initNavigationGuards(forceRedirectTo) {

		//user tries to open an direct URL or refreshes a browser
		this.router.onReady(() => {

			if (!this.isRouteAccessible(this.router.currentRoute)) {
				if (!this.isAuthenticated)
					this.router.push({ name: this.options.unauthorizedRedirectTo, query: { redirect: this.router.currentRoute.path } });
				else
					this.router.replace({ name: this.options.forbiddenRedirectTo });

				return;
			}

			if (forceRedirectTo) {
				let redirectTo = forceRedirectTo(this.router.currentRoute);

				if (redirectTo) {
					let resolved = this.router.resolve(redirectTo);

					if (resolved.route)
						this.router.push(resolved.route.path);
				}
			}
		});

		//user performs a regular navigation
		this.router.beforeEach((to, from, next) => {

			if (!this.isRouteAccessible(to)) {
				if (!this.isAuthenticated)
					next({ name: this.options.unauthorizedRedirectTo, query: { redirect: this.router.currentRoute.path } });
				else
					//Project Manager wants to logout and redirect to forbidden page
					this.logout(this.options.forbiddenRedirectTo);

				return;
			}

			if (forceRedirectTo) {
				let redirectTo = forceRedirectTo(to);

				if (redirectTo) {
					let resolved = this.router.resolve(redirectTo);

					if (resolved.route && resolved.route.path !== to.path) {
						next(resolved.route.path);
						return;
					}
				}
			}

			next();
		})
	}
}