
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

    @Component
    export default class ValidationSummary extends Vue {

        @Prop({ type: [String, Array], required: true, default: () => { return []; } })
        errors!: string | string[];

        @Prop({ type: Boolean, default: false })
        success!: boolean;

        errorsArr: string[] = [];

        @Watch('errors')
        errorsWatcher() {
            this.errorsArr = Array.isArray(this.errors) ? this.errors : [this.errors];
        }
    }
