import Vue from "vue";
import Component from "vue-class-component";
import NotificationViewModel from '../types/viewmodels/notification-view-model';
import { NotificationType } from "@/constants/notification-type";

@Component
export default class NotificationMixin extends Vue {
    notifications: NotificationViewModel[] = [];
    taskNotificationTypes: NotificationType[] = [];

    getNotificationMessage(notification: NotificationViewModel) {
        if (notification.type === NotificationType.FailedVisonScreening || notification.type === NotificationType.FailedHearingScreening)
            return notification.message;

        else {
            let html = `<strong>${notification.senderFullName}</strong>`;

            if (notification.type === NotificationType.CreateReferralMeeting || notification.type === NotificationType.CreateSimpleMeting) {
                html += notification.type === NotificationType.CreateSimpleMeting ? " invited you to Meeting '" : " invited you to Referral Meeting ";
                return html += `<strong>${notification.message}</strong> that will start <strong>${notification.meetTime}</strong>`;
            }

            if (notification.type === NotificationType.RequestDocument || notification.type === NotificationType.RequestedDocumentFilled) {
                html += notification.type === NotificationType.RequestedDocumentFilled ? " fulfilled " : " requested ";
                return html += `<strong>${notification.message}</strong> for student <strong>${notification.studentFullName}</strong>`;
            }

            if (notification.type === NotificationType.AcceptReferralMeeting || notification.type === NotificationType.AcceptSimpleMeting) {
                html += notification.type === NotificationType.AcceptSimpleMeting ? " accepted Meeting " : " accepted Referral Meeting ";
                return html += `<strong>'${notification.message}'</strong> that will start <strong>${notification.meetTime} </strong>`;
            }

            if (notification.type === NotificationType.DeclineReferralMeeting || notification.type === NotificationType.DeclineSimpleMeting) {
                html += notification.type === NotificationType.DeclineSimpleMeting ? " declined Meeting " : " declined Referral Meeting ";
                return html += `<strong>'${notification.message}'</strong> that will start <strong>${notification.meetTime}</strong>`;
            }
        }
    }

    getNotificationIcon(notification: NotificationViewModel) {
        if (notification.type === NotificationType.RequestDocument)
            return '<i class="material-icons text-secondary">insert_drive_file</i>';

        if (notification.type === NotificationType.RequestedDocumentFilled)
            return '<i class="material-icons text-primary">note_add</i>';

        if (notification.type === NotificationType.CreateReferralMeeting || notification.type === NotificationType.CreateSimpleMeting)
            return '<i class="material-icons text-secondary">calendar_today</i>';

        if (notification.type === NotificationType.AcceptReferralMeeting || notification.type === NotificationType.AcceptSimpleMeting)
            return '<i class="material-icons text-primary">event_available</i>';

        if (notification.type === NotificationType.DeclineReferralMeeting || notification.type === NotificationType.DeclineSimpleMeting)
            return '<i class="material-icons text-danger">event_busy</i>';

        if (notification.type === NotificationType.FailedVisonScreening || notification.type === NotificationType.FailedHearingScreening)
            return '<div class="student-list"><div class="heart-icon"></div></div>';
    }

    getTaskMessage(notificationType: NotificationType) {
        if (notificationType === NotificationType.CreateReferralMeeting || notificationType === NotificationType.CreateSimpleMeting)
            return "You have task on <strong>Meetings</strong> tab!"

        if (notificationType === NotificationType.RequestDocument)
            return `You have task on <strong>Requested Document</strong> tab!`;
    }
}