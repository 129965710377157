
    import { BModal } from 'bootstrap-vue/esm';
    import { Component, Vue } from 'vue-property-decorator';
    import { ModalForm } from '../../types/common/modal-form';

    @Component
    export default class IdleModal extends Vue implements ModalForm {
        $refs!: {
            modal: BModal
        }

        time = 0;

        async show() {
            this.$refs.modal.show();
            this.beforeShow();
        }

        beforeShow() {
            //Set 10 sec to close window
            this.time = 10000;

            let timerId = setInterval(() => {
                if (this.time > 1)
                    this.time -= 1000;

                if (!this.$store.state.idleVue.isIdle) {
                    clearInterval(timerId);
                    this.cancel();
                }

                if (this.time < 1)
                    this.$auth.logout();

            }, 1000);
        }

        cancel() {
            if (this.$refs.modal)
                this.$refs.modal.hide();
        }
    }
