import BaseService from "@/services/base-service";
import ContactUsViewModel from "../types/viewmodels/home/contact-us-view-model";

class HomeService extends BaseService {
    async contactUs(data: ContactUsViewModel) {
        try {
            await this.post('/home/contactUs', data);
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async getPrivacyPolicy() {
        try {
            return (await this.get<string>('/home/getPrivacyPolicy')).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }

    async getCleverLink() {
        try {
            return (await this.get<string>('/home/getCleverLink')).data;
        }
        catch (error) {
            this.ThrowError(error);
        }
    }
}

export default new HomeService();