import moment from 'moment';
import { DATE_FORMAT, BCALENDAR_FORMAT } from '@/constants/dates';
import { Month } from '../constants/month';

const Utils = {
    getWeekRange(num) {
        return [this.getWeekStart(num).toDate(), this.getWeekEnd(num).toDate()];
    },

    getWeekStart(num) {
        return moment().subtract(num, 'weeks').startOf('isoWeek');
    },

    getWeekEnd(num) {
        return moment().subtract(num, 'weeks').endOf('isoWeek');
    },

    getMonthRange(num) {
        return [this.getMonthStart(num).toDate(), this.getMonthEnd(num).toDate()];
    },

    getMonthStart(num) {
        return moment().subtract(num, 'month').startOf('month');
    },

    getMonthEnd(num) {
        return moment().subtract(num, 'month').endOf('month');
    },

    dateFormatToBCalenderFormat(date: string, isReverse: boolean) {
        let temp = moment(date, isReverse ? BCALENDAR_FORMAT : DATE_FORMAT);
        return moment(temp).format(isReverse ? DATE_FORMAT : BCALENDAR_FORMAT);
    },

    getAcademicYear() {
        let dt = new Date();
        return dt.getMonth() < Month.July ? dt.getFullYear() - 1 : dt.getFullYear();
    },

    getAcademicStartDate() {
        return new Date(this.getAcademicYear(), Month.July, 1);
    },

    getAcademicEndDate() {
        return new Date(this.getAcademicYear() + 1, Month.June, 30);
    }
};

export default Utils;