export enum NotificationType
{
    RequestDocument = 1,
    RequestedDocumentFilled,
    CreateReferralMeeting,
    AcceptReferralMeeting,
    DeclineReferralMeeting,
    CreateSimpleMeting,
    AcceptSimpleMeting,
    DeclineSimpleMeting,
    FailedVisonScreening,
    FailedHearingScreening
}