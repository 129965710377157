import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes
});

// Custom implementation of the missing onAbort global hook
// See https://github.com/vuejs/vue-router/issues/3157

const abortCallbacks = [];

// Registers onAbort callback
  // @ts-ignore: onAbort does not exist in interface
VueRouter.prototype.onAbort = callback => {
    abortCallbacks.push(callback);
};

const processAbortCallbacks = () => {
    abortCallbacks.forEach(callback => {
        callback();
    });
};

// "router.history" is undocumented api, but it is the only way to handle aborted navigations as of now
// @ts-ignore: history type is private
const historyTransitionTo = router.history.constructor.prototype.transitionTo;

// This method will be called for both "router.push" and "router.replace" methods under the hood
// @ts-ignore: history type is private
router.history.constructor.prototype.transitionTo = function extendedTransitionTo(location, onComplete, onAbort) {
    return historyTransitionTo.call(this, location, onComplete, error => {
        processAbortCallbacks();
        if (onAbort) {
            onAbort(error);
        }
    });
};

export default router;