import { Defaults } from '@entrypointdev/datatables.net-vue';

const defaults: Defaults = {
    tableClass: 'table',

    selectable: {
        selectAllCheckboxTemplate: (id) => `<div class='custom-control custom-checkbox'><input type='checkbox' id='${id}' class='custom-control-input' /><label class='custom-control-label' for='${id}'></label></div>`,
        checkboxTemplate: (id, cssClass, data, checked) => `<div class='custom-control custom-checkbox'><input type='checkbox' id='${id}' class='custom-control-input ${cssClass}' data-id='${data}' ${checked} /><label class='custom-control-label' for='${id}'></label></div>`
    },

    options: {
        language: {
            searchPlaceholder: 'Text'
        },

        dom: "rt<<'col d-flex justify-content-center'p>>",

        pageLength: 20
    },

    cssClasses: {
        sWrapper: "dataTables_wrapper",
        sFilterInput: "form-control form-control-sm",
        sLengthSelect: "form-control form-control-sm",
        sProcessing: "dataTables_processing card"
    }
};

export { defaults }