
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { UserPermission } from '../../../constants/user-permission';
    import notificationService from '../../../services/notification-service';

    @Component
    export default class SidebarMenuIcon extends Vue {
        notificationCount: number = 0;

        @Prop({ type: String })
        protected icon!: string;
        @Prop({ type: String })
        protected id!: string;
        @Prop({ type: Boolean, default: false })
        protected isNotification: boolean;

        async created() {
            if (this.isNotification) {
                this.notificationCount = await notificationService.getUserNotificationCount();
            }
        }

        async logout() {
            await this.$auth.logout();
        }

        get fullName() {
            if (!this.$auth.user)
                return '';

            return this.$auth.user.name;
        }

        get showAdminView() {
            return this.$auth.hasPermission(UserPermission.StudentsAccessAsAdministrator);
        }

        get showTeacherView() {
            return this.$auth.hasPermission(UserPermission.StudentsAccessAsTeacher);
        }

        get showMedicView() {
            return this.$auth.hasPermission(UserPermission.StudentsAccessAsMedic);
        }

        goToPage(page: string) {
            window.location.href = `${page}${(window.location.href.includes('HighSchool') ? '/HighSchool' : '')}`;
        }
        get isRequireReset() {
            return this.$auth.IsRequireReset === false || this.$auth.IsRequireReset === undefined ? true : false;
        }
    }
