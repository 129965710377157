
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import { DateRangeFilterMixin } from '@entrypointdev/datatables.net-vue';
    import { DATE_FORMAT, DEFAULT_RANGES, DATE_RANGE_SPLITTER } from '@/constants/dates';

    @Component
    export default class FilterDateRange extends Mixins(DateRangeFilterMixin) {

        @Prop({ type: [Object, Boolean], default: () => DEFAULT_RANGES })
        ranges!: object | boolean;

        //overriding to provide default value
        @Prop({ type: String, default: DATE_FORMAT })
        dateFormat!: string;

        //overriding to provide default value
        @Prop({ type: String, default: DATE_RANGE_SPLITTER })
        dateRangeSplitter!: string;

        @Prop({ type: String, default: 'right' })
        opens!: string;

        @Prop({ type: String, default: 'Date range' })
        placeholder!: string;

        @Prop({ type: String, default: 'form-control form-control-sm' })
        inputClass!: string;
    }
