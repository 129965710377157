import Vue from 'vue';
import ApiError from '@/utils/api-error';
import { AxiosRequestConfig, AxiosPromise, CancelTokenSource } from 'axios';

export default class BaseService {

    resetCancelTokenSource(source: CancelTokenSource[], newCancelTokenSource?: CancelTokenSource): CancelTokenSource[] {
        source.forEach(request => {
            try {
                request.cancel();
            } catch (error) {
                //ignore
            }
        });
        let newToken: CancelTokenSource;
        if (typeof newCancelTokenSource !== 'undefined') {
            newToken = newCancelTokenSource;
        } else {
            newToken = Vue.axios.CancelToken.source();
        }
        return [newToken];
    }
    
    get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
        return Vue.axios.get<T>(url, config);
    }

    protected post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
        return Vue.axios.post<T>(url, data, config);
    }

    protected ThrowError(error: any) {
        if (error.message !== undefined) {
            throw new ApiError(error);
        }
    }
}