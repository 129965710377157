import { GradeLevel } from "../constants/gradeLevel";
import { UserPermission } from "../constants/user-permission";

const documentTitles = () => import(/* webpackChunkName: "documentTitles" */ '@/components/views/admin/checklist/document-titles.vue');
const documentTables = () => import(/* webpackChunkName: "documentTables" */ '@/components/views/admin/checklist/document-tables.vue');
const districtExpectations = () => import(/* webpackChunkName: "districtExpectations" */ '@/components/views/admin/expectations/district-expectations.vue');
const behaviourExpectations = () => import(/* webpackChunkName: "behaviourExpectations" */ '@/components/views/admin/expectations/behavior-expectations.vue');
const selExpectations = () => import(/* webpackChunkName: "selExpectations" */ '@/components/views/admin/expectations/sel-expectations.vue');
const plcBehaviour = () => import(/* webpackChunkName: "plcBehaviour" */ '@/components/views/admin/plc/plc-behaviour.vue');
const plcBehaviourOffice = () => import(/* webpackChunkName: "plcBehaviourOffice" */ '@/components/views/admin/plc/plc-office-form.vue');
const plcBehaviourMetrix = () => import(/* webpackChunkName: "plcBehaviourMetrix" */ '@/components/views/admin/plc/plc-metrix-form.vue');
const plcSel = () => import(/* webpackChunkName: "plcSel" */ '@/components/views/admin/plc/plc-sel.vue');
const plcPlan = () => import(/* webpackChunkName: "plcPlan" */ '@/components/views/admin/plc/plc-plan.vue');
const plcUniversalPlan = () => import(/* webpackChunkName: "plcUniversalPlan" */ '@/components/views/admin/plc/plc-plan-universal.vue');
const plcUniversal = () => import(/* webpackChunkName: "plcUniversal" */ '@/components/views/admin/plc/plc-universal.vue');
const studentListMiddle = () => import(/* webpackChunkName: "studentListMiddle" */ '@/components/views/admin/students/student-list.vue');
const studentListHigh = () => import(/* webpackChunkName: "studentListHigh" */ '@/components/views/admin/students/student-list-high.vue');
const speechList = () => import(/* webpackChunkName: "speechList" */ '@/components/views/admin/speech-only/speech-list.vue');
const writtenExpressionList = () => import(/* webpackChunkName: "writtenExpressionList" */ '@/components/views/admin/written-expression/written-list.vue');
const allDocuments = () => import(/* webpackChunkName: "allDocuments" */ '@/components/views/admin/documents/all-documents.vue');
const districtsSchoolsList = () => import(/* webpackChunkName: "allDocuments" */ '@/components/views/admin/district/district-and-school.vue');

const routeOptions = [
    { path: '/', name: 'default', view: 'home', display: 'Home', meta: { showInMenu: false } },
    { path: '/home/clever', view: 'auth/clever', props: (route) => ({ code: route.query.code }) },
    { path: '/401-forbidden', name: 'forbidden', view: 'errors/401-forbidden', display: '401 Forbidden' },
    { path: '/404-not-found', name: 'not-found', view: 'errors/404-not-found', display: '404 Page Not Found' },
    { path: '/login', name: 'login', view: 'auth/login' },
    { path: '/auth/forgot-password', view: 'auth/forgot-password' },
    { path: '/auth/reset-password', view: 'auth/reset-password', props: (route) => ({ code: route.query.code }) },
    { path: '/auth/confirm-email', view: 'auth/confirm-email', props: (route) => ({ userId: route.query.userId, token: route.query.token }) },
    { path: '/student', view: 'admin/students/student-profile', display: 'Student', meta: { showInMenu: false, auth: { permissions: UserPermission.StudentsAccess } } },
    { path: '/Plc/PreparedOfficeForm/:schoolId/:year', name: 'plc-office', component: plcBehaviourOffice, display: 'Plc', meta: { showInMenu: false, auth: { permissions: UserPermission.PlcAccess } } },
    { path: '/Plc/PreparedSchoolWideForm/:schoolId/:year', name: 'plc-metrix', component: plcBehaviourMetrix, display: 'Plc', meta: { showInMenu: false, auth: { permissions: UserPermission.PlcAccess } } },
    { path: '/Plc/PreparedBehaviorForm', name: 'plc-plan-behaviour', component: plcPlan, display: 'Plc', meta: { showInMenu: false, auth: { permissions: UserPermission.PlcManagement } } },
    { path: '/Plc/PreparedSocialEmotionalLearningForm', name: 'plc-plan-sel', component: plcPlan, display: 'Plc', meta: { showInMenu: false, auth: { permissions: UserPermission.PlcManagement } } },
    { path: '/Plc/PreparedForm', name: 'plc-universal-plan', component: plcUniversalPlan, display: 'Plc', meta: { showInMenu: false, auth: { permissions: UserPermission.PlcAccess } } },
    { path: '/Student/AllDocuments/:studentId', component: allDocuments, display: 'All Documents', meta: { showInMenu: false, auth: { permissions: UserPermission.StudentsAccess } } },
    {
        path: '/profile',
        view: 'profile/profile',
        display: 'Profile',
        meta: { auth: true, showInMenu: false, icon: 'fa fa-user-circle', showChildren: true },
        children: [
            {
                path: '',
                display: 'My Profile',
                view: 'profile/edit-profile',
                meta: { auth: true },
            },
            {
                path: 'change-password',
                display: 'Change Password',
                view: 'profile/change-password',
                meta: { auth: true }
            },
            {
                path: 'set-password',
                display: 'Set Password',
                view: 'profile/set-password',
                meta: { auth: true }
            }
        ]
    },
    {
        path: '/Administrator',
        alias: ['/Teacher', '/Medic'],
        view: 'empty-layout',
        display: 'School Type',
        meta: { auth: { permissions: UserPermission.StudentsAccess }, showInMenu: true, icon: 'school', showChildren: true, isStudentListComponent: true },
        children: [
            {
                path: '',
                display: 'Elementary/Middle Schools',
                component: studentListMiddle,
                name: 'student-list-1',
                meta: { auth: { permissions: UserPermission.StudentsAccess } },
            },
            {
                path: 'HighSchool',
                display: 'High Schools/Early Colleges',
                component: studentListHigh,
                meta: { auth: { permissions: UserPermission.StudentsAccess } }
            }
        ]
    },
    {
        path: '/Plc',
        view: 'empty-layout',
        display: 'PLC Grade Levels',
        meta: { auth: { permissions: UserPermission.PlcAccess }, showInMenu: true, icon: 'pages', showChildren: true, isPLCComponent: true },
        children: [
            {
                path: '1',
                display: 'Elementary School',
                component: plcUniversal,
                name: 'plc-1',
                props: { gradeLevel: GradeLevel.Elementary },
                meta: { auth: { permissions: UserPermission.PlcAccess } },
            },
            {
                path: '2',
                display: 'Middle School',
                component: plcUniversal,
                name: 'plc-2',
                props: { gradeLevel: GradeLevel.Middle },
                meta: { auth: { permissions: UserPermission.PlcAccess } }
            },
            {
                path: '4',
                display: 'High School/Early College',
                component: plcUniversal,
                name: 'plc-4',
                props: { gradeLevel: GradeLevel.High },
                meta: { auth: { permissions: UserPermission.PlcAccess } }
            }
        ]
    },
    { path: '/ews', view: 'ews/ews', display: 'Ews', meta: { icon: 'warning', showInMenu: true, auth: { permissions: UserPermission.EWSAccess } } },
    {
        path: '',
        view: 'empty-layout',
        display: 'Dashboard',
        meta: { auth: { permissions: [UserPermission.VisionScreening, UserPermission.HearingScreening, UserPermission.SpeechScreening, UserPermission.InterventionGroupsAccess, UserPermission.NewProgressMonitoringTables, UserPermission.SpeechIEPAccess, UserPermission.WrittenExpressionAccess, UserPermission.EnvironmentalInventory] }, showInMenu: true, icon: 'dashboard', showChildren: true },
        children: [
            {
                path: '/student-screening',
                display: 'Screenings',
                view: 'empty-layout',
                meta: { auth: { permissions: [UserPermission.VisionScreening, UserPermission.HearingScreening, UserPermission.SpeechScreening] }, showChildren: true },
                children: [
                    {
                        path: 'vision-screening',
                        display: 'Vision Screenings',
                        view: 'admin/student-screening/student-screening',
                        meta: { auth: { permissions: UserPermission.VisionScreening }, sectionIndex: 0 }
                    },
                    {
                        path: 'hearing-screening',
                        display: 'Hearing Screenings',
                        view: 'admin/student-screening/student-screening',
                        meta: { auth: { permissions: UserPermission.HearingScreening }, sectionIndex: 1 }
                    },
                    {
                        path: 'speech-screening',
                        display: 'Speech Screenings',
                        view: 'admin/student-screening/student-screening',
                        meta: { auth: { permissions: UserPermission.SpeechScreening }, sectionIndex: 2 }
                    }
                ]
            },
            {
                path: '/intervention-group',
                display: 'Intervention Group',
                view: 'empty-layout',
                meta: { auth: { permissions: UserPermission.InterventionGroupsAccess }, showChildren: true },
                children: [
                    {
                        path: 'academics',
                        display: 'Intervention Groups',
                        view: 'intervention-group/intervention-group-list',
                        props: (route) => ({ isHighSchoolLeveL: false }),
                        meta: { auth: { permissions: UserPermission.InterventionGroupsAccess } }
                    },
                    {
                        path: 'high',
                        display: 'High School Intervention Groups',
                        view: 'intervention-group/intervention-group-list',
                        props: (route) => ({ isHighSchoolLeveL: true }),
                        meta: { auth: { permissions: UserPermission.InterventionGroupsAccess } }
                    },
                    {
                        path: 'behavior',
                        display: 'Behavior Intervention Groups',
                        view: 'behavior-intervention-group/behavior-intervention-group-list',
                        meta: { auth: { permissions: UserPermission.InterventionGroupsAccess } },
                    }]
            },
            {
                path: 'onlineProgressMonitoring',
                display: 'Online Progress Monitoring',
                view: 'online-progress-monitoring/online-progress-monitoring-list',
                meta: { auth: { permissions: UserPermission.NewProgressMonitoringTables } },
            },
            {
                path: 'Cycle/SpeechOnly',
                display: 'Speech Plan',
                component: speechList,
                meta: { auth: { permissions: UserPermission.SpeechIEPAccess } }
            },
            {
                path: 'Cycle/WrittenExpression',
                display: 'Written Expression Plan',
                component: writtenExpressionList,
                meta: { auth: { permissions: UserPermission.WrittenExpressionAccess } }
            },
            {
                path: 'Inventory',
                display: 'Environmental Inventory',
                view: 'inventory/inventory-list',
                meta: { auth: { permissions: UserPermission.EnvironmentalInventory } }
            }
        ]
    },
    { path: '/Report', view: 'report/report', display: 'Report', meta: { icon: 'equalizer', showInMenu: true, auth: { permissions: UserPermission.ReportsAccess } } },
    {
        path: '',
        view: 'empty-layout',
        display: '',
        meta: { auth: true, showInMenu: true, isFooterMenu: true, icon: 'notifications', showChildren: true, isNotification: true },
        children: [
            {
                path: 'Notification',
                display: 'Notification',
                view: 'notification/notification',
                meta: { auth: true, isNotification: true }
            },
            {
                path: 'Task',
                display: 'Task',
                view: 'task/task',
                meta: { auth: { permissions: UserPermission.TasksAccess }, isTask: true }
            }
        ]
    },
    {
        path: '',
        view: 'empty-layout',
        display: 'Settings',
        meta: { auth: { permissions: [UserPermission.PlcManagement, UserPermission.InterventionsManagement, UserPermission.CoreBestPracticesManagement, UserPermission.DistrictPMViewAccess, UserPermission.RoleManagement, UserPermission.ReportImport] }, showInMenu: true, isFooterMenu: true, icon: 'settings', showChildren: true },
        children: [
            {
                path: '/Plc',
                display: 'PLC',
                view: 'empty-layout',
                meta: { auth: true, showChildren: true },
                children: [
                    {
                        path: 'Manage',
                        display: 'District Tier 1 Expectations',
                        component: districtExpectations,
                        meta: { auth: { permissions: UserPermission.DistrictTier1ExpectationsManagement } }
                    },
                    {
                        path: 'BehaviorManage',
                        display: 'Behavior Tier 1 Expectations',
                        component: behaviourExpectations,
                        meta: { auth: { permissions: UserPermission.BehaviorTier1ExpectationsManagement } }
                    },
                    {
                        path: 'SocialEmotionalLearningManage',
                        display: 'Competency Tier 1 Expectations',
                        component: selExpectations,
                        meta: { auth: { permissions: UserPermission.SocialEmotionalTier1ExpectationsManagement } }
                    },
                    {
                        path: 'Behavior',
                        display: 'Behavior PLC Plan',
                        component: plcBehaviour,
                        name: 'plc-behaviour',
                        meta: { auth: { permissions: UserPermission.BehaviorPLCPlanManagement } }
                    },
                    {
                        path: 'PreparedSocialEmotionalLearning',
                        display: 'Competency PLC Plan',
                        component: plcSel,
                        name: 'plc-sel',
                        meta: { auth: { permissions: UserPermission.SocialEmotionalLearningPLCPlanManagement } }
                    },
                ]
            },
            {
                path: '/intervention',
                display: 'Interventions',
                view: 'empty-layout',
                meta: { auth: { permissions: UserPermission.InterventionsManagement }, showChildren: true },
                children: [
                    {
                        path: 'tools',
                        display: 'Intervention tools',
                        view: 'intervention/intervention-tool',
                        meta: { auth: { permissions: UserPermission.InterventionToolsManagement } }
                    },
                    {
                        path: 'tables',
                        display: 'Intervention tables',
                        view: 'intervention/intervention-table',
                        meta: { auth: { permissions: UserPermission.InterventionTablesManagement } }
                    }
                ]
            },
            {
                path: '/practices',
                display: 'Core Best Practices',
                view: 'empty-layout',
                meta: { auth: { permissions: UserPermission.CoreBestPracticesManagement }, showChildren: true },
                children: [
                    {
                        path: 'tools',
                        display: 'CBP tools',
                        view: 'core-practice/core-practice-tool',
                        meta: { auth: { permissions: UserPermission.PracticesToolsManagement } }
                    },
                    {
                        path: 'tables',
                        display: 'CBP tables',
                        view: 'core-practice/core-practice-table',
                        meta: { auth: { permissions: UserPermission.PracticesTablesManagement } }
                    }
                ]
            },
            {
                path: 'districtProgressMonitoringTool',
                display: 'Intervention Group PM Tools',
                view: 'progress-monitoring-tool/progress-monitoring-tool-list',
                meta: { auth: { permissions: UserPermission.DistrictPMViewAccess } }
            },
            {
                path: 'ReportImport',
                display: 'Import of Reports',
                view: 'admin/report-import/report-import',
                meta: { auth: { permissions: UserPermission.ReportImport } }
            },
            {
                path: 'OnlineProgressMonitoringImport',
                display: 'Import of Online Progress Monitoring',
                view: 'admin/opm-import/opm-import',
                meta: { auth: { permissions: UserPermission.GlobalLevelAccess } }
            },
            {
                path: 'User/Manage',
                display: 'User Management',
                view: 'admin/users/user-list',
                meta: { auth: { permissions: UserPermission.RoleManagement } }
            }
        ]
    },
    {
        path: '',
        display: 'Site Management',
        view: 'empty-layout',
        meta: { auth: { permissions: [UserPermission.ChecklistManagement, UserPermission.ImportManagement, UserPermission.StudentsManagement, UserPermission.SchoolsManagement, UserPermission.DistrictsManagement, UserPermission.GlobalLevelAccess ] }, showInMenu: true, isFooterMenu: true, icon: 'vpn_lock', showChildren: true },
        children: [
            {
                path: '/Administrator',
                display: 'Checklist',
                view: 'empty-layout',
                meta: { auth: { permissions: UserPermission.ChecklistManagement }, showChildren: true },
                children: [
                    {
                        path: 'ChecklistManage',
                        display: 'Checklist tables',
                        component: documentTables,
                        meta: { auth: { permissions: UserPermission.ChecklistTablesManagement } }
                    },
                    {
                        path: 'ChecklistTitleManage',
                        display: 'Checklist document titles',
                        component: documentTitles,
                        meta: { auth: { permissions: UserPermission.ChecklistDocumentTitleManagement } }
                    }
                ]
            },
            {
                path: '/Administrator',
                display: 'Imports',
                view: 'empty-layout',
                meta: { auth: { permissions: UserPermission.ImportManagement }, showChildren: true },
                children: [
                    {
                        path: 'ImportSetting',
                        display: 'Import Settings',
                        view: 'admin/import-setting/import-setting-list',
                        meta: { auth: { permissions: UserPermission.ImportManagement } }
                    }
                ]
            },
            {
                path: 'Student/Manage',
                display: 'Students',
                view: 'admin/students/students-manage-list',
                meta: { auth: { permissions: UserPermission.StudentsManagement } }
            },
            {
                path: 'Administrator/DistrictSchool?isSchool=true',
                display: 'Schools',
                component: districtsSchoolsList,
                props: { isSchool: true },
                meta: { auth: { permissions: UserPermission.SchoolsManagement } }
            },
            {
                path: 'Administrator/DistrictSchool',
                display: 'Districts',
                component: districtsSchoolsList,
                props: { isSchool: false },
                meta: { auth: { permissions: UserPermission.DistrictsManagement } }
            },
            {
                path: 'RolePermission',
                display: 'Role Permissions',
                view: 'admin/role-permission/role-permission',
                meta: { auth: { permissions: UserPermission.GlobalLevelAccess } }
            }
        ]
    },
    { path: '*', redirect: { name: 'not-found' } }
];

function addDynamicImport(route) {
    if (!route.view)
        return route;

    if (route.children && route.children.length) {
        route.children = route.children.map(child => {
            return addDynamicImport(child);
        });
    }

    return {
        ...route,
        component: () => import(/* webpackChunkName: "[request]" */ `@/components/views/${route.view}`)
    }
}

const routes = routeOptions.map(route => {
    return addDynamicImport(route);
})

export { routes }