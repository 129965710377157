
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import { SelectFilterMixin } from '@entrypointdev/datatables.net-vue';
    import { SelectListOption } from '@entrypointdev/datatables.net-vue/dist/src/components/filters/mixins/select-mixin';

    @Component
    export default class FilterSelect extends Mixins(SelectFilterMixin) {
        @Prop({ type: String, default: 'Select one' })
        placeholder!: string;

        @Prop({ type: String })
        size?: string;

        @Prop({ type: Boolean, default: true })
        showLabels?: boolean;

        get deselectLabel() {
            return this.allowEmpty ? 'Press enter to remove' : "Can't remove";
        }

        getSelectedOption() {
            return this.selectedValue;
        }

        setSelectedOption(custom: SelectListOption) {
            this.changeQuery(custom);
        }
    }
